<template>
  <div
    class="sm:px-10 sm:pt-12 sm:pb-10 flex flex-col text-center items-center justify-center"
  >
    <h1 class="text-xl text-grayscale-96 leading-160 font-druk-wide pt-16">
      <span v-if="customTitle">
        {{ customTitle }}
      </span>

      <span v-else>
        {{
          proOnboarding
            ? "Let’s Create Your Xposure Pro Account"
            : "Create an Xposure Account"
        }}
      </span>
    </h1>

    <p v-if="!proOnboarding" class="mt-6 text-manatee text-sm font-medium">
      Already have an account?
      <button class="text-blue-haze hover:text-accent-orange" @click="goLogin">
        Login
      </button>
    </p>

    <div class="w-full mt-14 flex justify-between">
      <div class="w-full flex">
        <SocialLoginButton
          :loginType="SocialLoginTypes.google"
          @onSocialClick="onSocialClick"
        />
        <!-- <SocialLoginButton
            :loginType="SocialLoginTypes.twitter"
            @onSocialClick="onSocialClick"
          /> -->
      </div>
    </div>

    <p class="mt-6 text-manatee text-13 leading-160">Or</p>

    <form class="w-full mt-6">
      <input-field
        v-model:value="fullName"
        name="fullName"
        placeholder="Full Name"
        type="text"
        class="mb-2"
        :is-disabled="isLoading"
        v-model:error-message="errors.fullNameError"
        @clearError="clearError"
        @proceed="checkInput"
      />
      <input-field
        v-model:value="email"
        name="email"
        placeholder="Email"
        type="text"
        :is-disabled="isLoading"
        v-model:error-message="errors.emailError"
        @clearError="clearError"
        @proceed="checkInput"
      />
      <input-field
        v-model:value="password"
        name="password"
        placeholder="Password"
        type="password"
        class="mt-2"
        :is-disabled="isLoading"
        v-model:error-message="errors.passwordError"
        @clearError="clearError"
        @proceed="checkInput"
      />
    </form>
    <span v-if="errorMessage" class="text-accent-red text-13">{{
      errorMessage
    }}</span>

    <general-button
      :is-loading="isLoading"
      @click="checkInput"
      class="w-full mt-8"
    >
      {{ proOnboarding ? "Create PRO account" : "Sign Up" }}
    </general-button>

    <span class="mx-3 mt-6 text-manatee text-13 leading-160 sm:mx-4">
      By creating an account, you are agreeing to our
      <span>
        <a
          class="text-blue-haze"
          href="https://info.xposuremusic.com/legal/terms-of-service"
          target="_blank"
          >Terms of Service</a
        >
      </span>
      and
      <span>
        <a
          class="text-blue-haze"
          href="https://info.xposuremusic.com/legal/privacy-policy"
          target="_blank"
          >Privacy Policy</a
        >
      </span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { useAuthenticationStore } from "@/store/authentication";
import { useLocalNotificationsStore } from "@/store/notifications";
import {
  AuthActionTypes,
  SignUpError,
  SocialLoginTypes,
} from "@/types/authentication";
import ApiError from "@/types/error";
import { runWithDelay, scrollToFirstError } from "@/utils/helpers";
import { validateEmail, validatePassword } from "@/utils/validation";
import { reactive, ref } from "vue";
import SocialLoginButton from "../layout/buttons/SocialLoginButton.vue";
import InputField from "../layout/inputs/InputField.vue";
import GeneralButton from "@/components/layout/buttons/GeneralButton.vue";

const router = useRouter();

const props = defineProps({
  customTitle: {
    type: String,
    default: "",
  },
  proOnboarding: {
    type: Boolean,
    default: false,
  },
  proCode: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["changeAuthType", "userAuthenticated"]);
const errors = reactive({
  fullNameError: "",
  emailError: "",
  passwordError: "",
} as SignUpError);
const fullName = ref("");
const email = ref("");
const password = ref("");
const isLoading = ref(false);
const errorMessage = ref("");

const authenticationStore = useAuthenticationStore();
const localNotifications = useLocalNotificationsStore();

const checkInput = async (): Promise<void> => {
  isLoading.value = true;
  errors.fullNameError = fullName.value ? "" : "Full name is required";
  errors.emailError = validateEmail(email.value);
  errors.passwordError = validatePassword(password.value);

  if (errors.fullNameError || errors.emailError || errors.passwordError) {
    scrollToFirstError(errors);
    isLoading.value = false;
    return;
  }

  try {
    await authenticationStore.register(
      fullName.value,
      email.value,
      password.value,
      props.proCode,
    );

    window.analytics &&
      window.analytics.track("Signed Up", {
        email: email.value,
      });

    if (props.proOnboarding) {
      router.push("/post-onboarding");
    }
    authenticationStore.setModalState({
      isModalVisible: false,
    });

    emit("userAuthenticated");
  } catch (error) {
    errorMessage.value = (error as ApiError).message;
    runWithDelay(() => (errorMessage.value = ""));
  } finally {
    isLoading.value = false;
  }
};

const googleLogin = async () => {
  try {
    const { url } = await authenticationStore
      .getAuthService()
      .getGoogleLoginUrl(props.proCode);
    localStorage.setItem("google_code_pro", props.proCode || "");
    window.location.href = url;
    authenticationStore.setModalState({
      isModalVisible: false,
    });
  } catch (error: any) {
    localNotifications.addNotification({
      message: error.message,
      isError: true,
    });
  }
};

const twitterLogin = async () => {
  try {
    const loginUrl: any = await authenticationStore.getTwitterLoginUrl();
    localStorage.setItem("twitter_code_verifier", loginUrl.verifier);
    localStorage.setItem("twitter_code_pro", props.proCode || "");

    if (props.proCode) {
      authenticationStore.setModalState({
        isModalVisible: false,
        redirectUrl: "/post-onboarding",
      });
    }
    window.location.href = loginUrl.url;
  } catch (error: any) {
    localNotifications.addNotification({
      message: error.message,
      isError: true,
    });
  }
};

const onSocialClick = async (type: SocialLoginTypes) => {
  const actionsBySocialType = {
    [SocialLoginTypes.google]: googleLogin,
    [SocialLoginTypes.twitter]: twitterLogin,
  };
  try {
    await actionsBySocialType[type]?.();
  } catch (error) {
    errorMessage.value = (error as ApiError).message;
    runWithDelay(() => (errorMessage.value = ""));
  }
};

const goLogin = () => {
  emit("changeAuthType", AuthActionTypes.login);
};
</script>
